<template>
  <section>
    <liff-list :listKey="listKey"></liff-list>
  </section>
</template>

<script lang="babel" type="text/babel">
import listMixin from "@/components/list/mixins/listMixin.js"
import listConfig from "./productList"

export default {
  mixins: [listMixin],
  components: {

  },
  data: () => ({
    listKey: 'liff-store-product-list',
    meta: {
      productMenus: [],
      favorites: [],
    },
  }),
  computed: {
    hasStore() {
      return this.$store.getters["member/hasStore"];
    },
    checkCooperation() {
      return this.$route.query.checkCooperation
    },
    storeId() {
      return this.$store.getters['member/storeId']
    },
    providerId() {
      return this.$route.params.providerId
    },
    isCooperation() {
      return this.$store.getters['store/isCooperation']
    },
    searchKeyword() {
      return this.$store.getters[`list/${this.listKey}/searchKeyword`]
    },
  },
  mounted() {
    this.$root.gaLogEvent('商店_商品頁')
  },
  async created() {
    if(this.checkCooperation) {
      // 檢查是否有合作關係
      if(!this.isCooperation) {
        this.router.replace({
          name: 'store-register', 
          params: {providerId: this.providerId}
        })
        return
      }
    }
    await this.$store.dispatch('store/setStoreByApi', {vm: this, storeId: this.storeId, providerId: this.providerId })
  },
  methods: {
    async beforeIndex() {
      this.$store.dispatch('loading/active')
      try {
        await Promise.all([this.getProductMenus(), this.getFavorite()])
      } catch(err) {
        console.error(err)
      } finally {
        this.$store.dispatch('loading/close')
      }
    },
    async getFavorite() {
      if (!this.hasStore) {
        this.meta.favorites = []
        return
      }
      const res = await this.$api.collection.favoriteApi.index(this.storeId, this.providerId)
      this.meta.favorites = res.map(item => item.product_id)
    },
    async getProductMenus() {
      let productMenus = await this.$api.collection.productMenuApi.index(this.providerId, {pager: 0})
      productMenus = productMenus
          .filter(menu => {
            return menu.status == 1
          })
          .map(menu => ({
            text: menu.name,
            value: menu.id
          }))
      productMenus.unshift({text: '全部', value: 'null'})
      this.meta.productMenus = productMenus
    },
    async indexApi(params) {
      if(params.filter && params.filter.id === 'null') delete params.filter.id
      // 未註冊商店, 使用公開的商品列表api
      if (!this.hasStore) {
        return await this.$api.collection.storeProductApi.publicIndex(this.providerId, Object.assign(params, {status: 1}))
      }
      return await this.$api.collection.storeProductApi.index(this.storeId, this.providerId, Object.assign(params, {status: 1}))
    },
    async patchApi(value, row, config) {
      const target = row.id
      const column = config.key
      return await this.$api.collection.productApi.patch(this.providerId, target, column, value)
    },
    async deleteApi(target) {
      return await this.$api.collection.productApi.delete(this.providerId, target)
    },
    async batchApi(targets, column, value) {
      return await this.$api.collection.productApi.batch(this.providerId, targets, column, value)
    },
    async batchDeleteApi(targets) {
      return await this.$api.collection.productApi.batchDelete(this.providerId, targets)
    },
    getListConfig() {
      return listConfig
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
